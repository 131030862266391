<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en'
            ? 'Select all that apply.'
            : 'Sélectionnez toutes les bonnes réponses possibles.'
        }}</b
        >.
        {{
          inputs.language === 'en'
            ? 'In which of the following solvent combinations would the aqueous phase be at the bottom?'
            : 'Dans laquelle/lesquelles des combinaisons de solvants suivants la phase aqueuse se trouverait-elle en bas?'
        }}
      </p>
      <br />
      <p
        v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
        :key="'pt-5-' + option.value"
        class="mb-0 mt-0"
      >
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
      <br />

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab3Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Benzene/water', value: 'benzene'}, // Density of 0.876 g/mL
        {text: 'Pentane/water', value: 'pentane'}, // Density of 0.626 g/mL
        {text: 'Dichloromethane/water', value: 'dichloromethane'}, // Density of 1.33 g/mL
        {text: 'Chloroform/water', value: 'chloroform'}, // Density of 1.49 g/mL
        {text: '${n}$-Butanol/water', value: 'nButanol'}, // Density of 0.810 g/mL
      ],
      optionsFr: [
        {text: 'Pentane/eau', value: 'pentane'},
        {text: '${n}$-Butanol/eau', value: 'nButanol'},
        {text: 'Benzène/eau', value: 'benzene'},
        {text: 'Chloroforme/eau', value: 'chloroform'},
        {text: 'Dichlorométhane/eau', value: 'dichloromethane'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
